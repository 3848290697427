<template>
  <div class="deviceSkim">
    <div
      class="facilityBox"
      style="min-width: 1376px;width:100%;box-sizing: border-box;"
    >
      <!-- 装货地卸货地 -->
      <div class="content_box">
        <div class="shipment">
          <div class="shipment_item">
            <div class="address_box" style="height: 31.5vh">
              <div
                class="address_item"
                v-for="(item, index) in fenceList"
                :key="index"
              >
                <div style="font-weight: 600;min-width: 78px">
                  {{ item.type == "start" ? "装货地围栏" : "卸货地围栏" }}：
                </div>
                <div style="border-bottom: 1px dashed">{{ item.address }}</div>
                <el-button
                  class="item_btn"
                  type="text"
                  size="mini"
                  @click="removeFence(item, index)"
                  >删除</el-button
                >
              </div>
            </div>
            <el-radio-group
              v-model="PointFlag"
              @change="changePointFlag"
              class="addressFence_radio"
            >
              <el-radio
                v-for="item of PointFlagList"
                name="PointFlag"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
            <el-button
              type="primary"
              class="addressFence_search"
              size="medium"
              @click="fenceSearch"
              >装货地卸货地查询</el-button
            >
          </div>
          <div
            class="shipment_item"
            style="border-top:none"
            v-loading="loadingFence"
          >
            <div class="address_box">
              <div
                class="address_item"
                v-for="(item, index) in addressList"
                :key="index"
              >
                <div class="item_address">
                  <p>
                    <span style="font-weight: 600">装货地：</span
                    ><span style="border-bottom: 1px dashed">{{
                      item.LoadAddress
                    }}</span>
                  </p>
                  <p>
                    <span style="font-weight: 600">卸货地：</span
                    ><span style="border-bottom: 1px dashed">{{
                      item.UnLoadAddress
                    }}</span>
                  </p>
                </div>
                <el-button
                  class="item_btn"
                  type="text"
                  size="mini"
                  @click="removeAddress(item)"
                  >删除</el-button
                >
              </div>
            </div>
            <el-button
              type="primary"
              class="addressFence_search"
              size="medium"
              @click="locusSearch"
              >轨迹查询</el-button
            >
          </div>
        </div>
        <div v-loading="loading" class="mapBox">
          <div>
            <div class="searchBox" style="height: 7vh">
              <el-form
                class="deviceForm"
                :model="bindForm"
                ref="bindForm"
                :inline="true"
              >
                <el-form-item
                  label="车牌号或设备IMEI"
                  prop="VehicleCarNumber"
                  label-width="126px"
                >
                  <el-input
                    v-model="bindForm.VehicleCarNumber"
                    placeholder="车牌号或设备IMEI"
                    style="width: 146px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="车辆颜色">
                  <el-select
                    v-model="vehicleColor"
                    placeholder="请选择车辆颜色"
                    style="width: 76px"
                  >
                    <el-option label="蓝色" value="1"></el-option>
                    <el-option label="黄色" value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="选择时间范围"
                  prop="time"
                  label-width="100px"
                >
                  <!--  @change="handleTime" -->
                  <el-date-picker
                    :default-time="defaultTime"
                    style="width: 356px"
                    v-model="bindForm.time"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    size="medium"
                    @click="search('bindForm')"
                    >搜索</el-button
                  >
                  <el-button
                    type="primary"
                    size="medium"
                    @click="resetForm('bindForm')"
                    >清空</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <div id="container" style="width:auto;height:76vh;"></div>
            <ul class="btn-wrap" style="z-index: 9999999;" v-show="btnShow">
              <li class="light btn" @click="startMove">开始</li>
              <li class="night btn" @click="pauseMove">暂停</li>
              <li class="night btn" @click="resumeMove">继续</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="locus_btnBox">
          <!-- <el-checkbox-group v-model="PointLIst" @change="changePointFlag">
            <el-checkbox
             v-for="item of PointFlagList"
              name="PointFlag"
              :key="item.value"
              :label="item.value">{{item.label}}</el-checkbox>
          </el-checkbox-group> -->
          <el-button
            type="primary"
            class="address_search"
            size="medium"
            @click="exportExcel"
            >导出</el-button
          >
          <el-input
            placeholder="请输入有效里程（KM）"
            v-model="mileage"
            class="input-with-select"
            style="width: 234px; margin-left: 20px"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleMileage"
            ></el-button>
          </el-input>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          v-loading="loadingTable"
          v-if="isDanger"
        >
          <el-table-column type="index" label="编号" />
          <el-table-column prop="VehicleNumber" label="车牌号/设备IMEI">
            <template slot-scope="scope">
              <span :class="scope.row.dander ? 'warning-row' : ''">{{
                scope.row.VehicleNumber
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="Mileage" label="里程（km）">
            <template slot-scope="scope">
              <span :class="scope.row.dander ? 'warning-row' : ''">{{
                scope.row.Mileage
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="loadingPoint" label="装货地址">
            <template slot-scope="scope">
              <span :class="scope.row.dander ? 'warning-row' : ''">{{
                scope.row.loadingPoint
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="unloadingPoint" label="卸货地址">
            <template slot-scope="scope">
              <span :class="scope.row.dander ? 'warning-row' : ''">{{
                scope.row.unloadingPoint
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="startDate" label="装货时间">
            <template slot-scope="scope">
              <span :class="scope.row.dander ? 'warning-row' : ''">{{
                scope.row.startDate
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="endDate" label="卸货时间">
            <template slot-scope="scope">
              <span :class="scope.row.dander ? 'warning-row' : ''">{{
                scope.row.endDate
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template
              slot-scope="scope"
              style="display: flex;justify-content: center;"
            >
              <el-button
                type="primary"
                size="small"
                @click="removelocus(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEquipDetail,
  GetLoadingPointList,
  getEquipTrack,
  GetMileageInfo,
  ExportMileageInfo,
} from "@/api/platform/deviceSkim/index";
import start from "../../../assets/static/start.png";
import end from "../../../assets/static/end.png";
import address from "@/assets/static/address.png";
import { reverseCoord } from "@/api/common/common";
import car from "../../../assets/static/car1.png";
export default {
  data() {
    return {
      innerVisible: false,
      loading: false,
      type: 0, // 判断是查询点 还是查询轨迹 1是点 2是轨迹
      site: {
        lng: "",
        lat: "",
      }, // 中心点
      bindForm: {
        VehicleCarNumber: "", // 车牌号
        deviceNum: "", // 设备名
        time: [], // 时间
        status: "", // 状态
        deviceOwner: "", // 设备所有人
        DriverPhone: "", // 司机电话号码
      },
      options: [
        {
          value: "1",
          label: "启用",
        },
        {
          value: "2",
          label: "禁用",
        },
      ],
      stateList: [
        {
          id: 1,
          name: "是否启用",
          isActive: 1,
        },
        {
          id: 2,
          name: "是否激活",
          isActive: 1,
        },
        {
          id: 3,
          name: "是否过期",
          isActive: 1,
        },
        {
          id: 4,
          name: "ACG状态",
          isActive: 1,
        },
        {
          id: 5,
          name: "网络状态",
          isActive: 1,
        },
      ], // 设备状态列表
      //  设备详情
      deviceInfo: {},
      traceInfo: [], // 轨迹信息
      map: null, // 地图
      marker: null,
      btnShow: false, // 是否展示按钮组
      defaultTime: ["00:00:00", "23:59:59"], // 默认的选择时间
      points: [],
      activeNames: ["1"],
      addressList: [], // 装货地卸货地
      fenceList: [], // 围栏
      vehicleColor: "2",
      newsite: {
        lng: "",
        lat: "",
      },
      newtraceInfo: [], // 轨迹信息
      polylineLayer: null,
      tableData: [],
      redisKey: "",
      infoWindow: null,
      circle: null, // 原型覆盖物
      radiusValue: 1000,
      PointFlagList: [
        { label: "所有运单轨迹", value: 0 },
        { label: "起点到起点", value: 1 },
        { label: "起点到终点", value: 2 },
        { label: "终点到起点", value: 4 },
        { label: "终点到终点", value: 8 },
      ], // 起始类型
      PointLIst: [], //
      PointFlag: 0,
      loadingFence: false,
      loadingTable: false,
      newArrList: [],
      mileage: "",
      isDanger: true,
      mileageList: [],
    };
  },
  mounted() {
    this.initMap();
  },
  created() {
    this.getNowTime();
    window.shipment = this.shipment;
    window.unloading = this.unloading;
  },
  methods: {
    search(bindForm) {
      if (!this.bindForm.VehicleCarNumber) {
        this.$message.warning("请输入车牌号或设备IMEI");
        return;
      }
      if (this.bindForm.VehicleCarNumber && this.bindForm.time.length > 0) {
        this.getEquipTrack();
      } else if (
        this.bindForm.VehicleCarNumber &&
        this.bindForm.time.length == 0
      ) {
        this.getEquipDetail();
      }
      this.tableData = [];
      this.addressList = [];
      this.fenceList = [];
    },
    resetForm(bindForm) {
      this.$refs[bindForm].resetFields();
      this.tableData = [];
      this.addressList = [];
      this.fenceList = [];
      this.map.destroy();
      this.initMap();
    },
    // 获取设备详情
    getEquipDetail() {
      let params = {
        //imei: this.bindForm.deviceNum
        searchWord: this.bindForm.VehicleCarNumber,
        coordType: 2, // 1是百度地图轨迹 2是腾讯地图轨迹
      };
      this.loading = true;
      getEquipDetail(params)
        .then((res) => {
          this.deviceInfo = res.data;
          this.stateList[0].isActive = res.data.IsUse;
          this.stateList[1].isActive = res.data.IsActive;
          this.stateList[2].isActive = res.data.IsValid;
          this.stateList[3].isActive = res.data.ACGStatus;
          this.stateList[4].isActive = res.data.NetworkStatus;
          this.site.lng = res.data.Longitude;
          this.site.lat = res.data.Latitude;
          this.type = 1;
          this.btnShow = false;
          this.loading = false;
          this.setSite();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 处理选择时间
    handleTime(val) {
      var date = new Date();
      let data1 = new Date(val[0]).getTime();
      let data2 = new Date(val[1]).getTime();
      if (data2 - data1 > 86400000 * 10) {
        this.$message.warning("只能查询10天内的轨迹");
        this.bindForm.time = [];
        return;
      }
      this.getNowTime();
    },
    // 获取当前的时分秒
    getNowTime() {
      let time = new Date();
      let hh = time.getHours();
      let mf =
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
      let ss =
        time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
      this.defaultTime[1] = hh + ":" + mf + ":" + ss;
    },
    // 查询轨迹
    getEquipTrack() {
      let params = {
        searchWord: this.bindForm.VehicleCarNumber,
        stDate: this.bindForm.time[0],
        edDate: this.bindForm.time[1],
        coordType: 2, // 1是百度地图轨迹 2是腾讯地图轨迹
        redisKey: this.redisKey,
      };
      this.loading = true;
      this.points = [];
      getEquipTrack(params)
        .then((res) => {
          if (res.data.list.length > 0) {
            this.redisKey = res.data.redisKey;
            let list = res.data.list;
            this.site.lng = list[0].Lng;
            this.site.lat = list[0].Lat;
            let arr = list.map((item) => {
              return new TMap.LatLng(item.Lat, item.Lng);
            });
            this.traceInfo = arr;
            this.type = 2;
            this.btnShow = true;
            this.loading = false;
            let newArr = list.slice();
            newArr.forEach((item, index) => {
              if (item.IsStop) {
                this.points.push({
                  id: index + 2,
                  styleId:
                    index === 0
                      ? "start"
                      : index === newArr.length - 1
                      ? "end"
                      : "newmarker",
                  position: new TMap.LatLng(item.Lat, item.Lng),
                  Address: item.Address,
                  properties: {
                    //自定义属性
                    time: item.GPSTime,
                    IsStop: item.IsStop,
                    TimeDiffer: item.TimeDiffer,
                  },
                });
              } else {
                this.points.push({
                  id: index + 2,
                  styleId:
                    index === 0
                      ? "start"
                      : index === newArr.length - 1
                      ? "end"
                      : "marker",
                  position: new TMap.LatLng(item.Lat, item.Lng),
                  Address: item.Address,
                  properties: {
                    //自定义属性
                    time: item.GPSTime,
                    IsStop: item.IsStop,
                    TimeDiffer: item.TimeDiffer,
                  },
                });
              }
            });
            // 设置中心点
            this.setRoute();
          } else {
            this.$message.warning("该时间段暂无轨迹");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // ------------------------------------------------------------------------
    //  地图API
    initMap() {
      this.center = new TMap.LatLng(28.606169, 115.934794);
      this.map = new TMap.Map("container", {
        center: this.center,
      });
    },
    // 设置车辆最新的位置
    setSite() {
      let _self = this;
      this.map.destroy();
      this.center = new TMap.LatLng(this.site.lat, this.site.lng);
      this.map = new TMap.Map("container", {
        center: this.center,
      });
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 46, // 点标记样式宽度（像素）
            height: 38, // 点标记样式高度（像素）
            src: car, //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            //点标注数据数组
            id: "demo",
            styleId: "marker",
            position: new TMap.LatLng(this.site.lat, this.site.lng),
            properties: {
              title: "marker",
            },
          },
        ],
      });
      //初始化mark标记点点击事件
      var infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      infoWindow.close(); //初始关闭信息窗关闭
      markerLayer.on("click", function(evt) {
        infoWindow.open(); //打开信息窗
        infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
        //设置信息窗内容
        infoWindow.setContent(`<div>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">地址：</span>${
            _self.deviceInfo.PrecisePosition
          }</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${
            _self.deviceInfo.Latitude
          }-${_self.deviceInfo.Longitude}</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">物联设备：</span>${
            _self.deviceInfo.IMEI ? _self.deviceInfo.IMEI : "暂无"
          }</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">设备所有人：</span>${
            _self.deviceInfo.EquipOwnerName
              ? _self.deviceInfo.EquipOwnerName
              : "暂无"
          }</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">车牌号/司机手机：</span>
          ${
            _self.deviceInfo.VehicleNo ? _self.deviceInfo.VehicleNo : "暂无"
          }/${_self.deviceInfo.DriverPhone ? _self.deviceInfo.DriverPhone : "暂无"}</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">最后定位时间：</span>${
            _self.deviceInfo.GPSTime
          }</p>
          </div>`);
      });
    },
    // 查看车辆轨迹
    setRoute() {
      let _self = this;
      this.map.destroy();
      this.center = new TMap.LatLng(this.site.lat, this.site.lng);
      this.map = new TMap.Map("container", {
        center: this.center,
      });
      this.polylineLayer = new TMap.MultiPolyline({
        map: this.map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          style_blue: new TMap.PolylineStyle({
            color: "#3777FF", //线填充色
            width: 8, //折线宽度
            borderWidth: 2, //边线宽度
            borderColor: "#FFF", //边线颜色
            lineCap: "butt", //线端头方式
            showArrow: true,
            arrowOptions: {
              width: 8,
              height: 8,
              space: 20,
            },
          }),
          style_red: new TMap.PolylineStyle({
            color: "#00cc00", //线填充色
            width: 10, //折线宽度
            borderWidth: 2, //边线宽度
            borderColor: "#FFF", //边线颜色
            lineCap: "round", //线端头方式
            showArrow: true,
            arrowOptions: {
              width: 8,
              height: 8,
              space: 20,
            },
          }),
        },
        geometries: [
          {
            styleId: "style_blue",
            paths: this.traceInfo,
          },
        ],
      });
      this.marker = new TMap.MultiMarker({
        map: this.map,
        styles: {
          "car-down": new TMap.MarkerStyle({
            width: 40,
            height: 40,
            anchor: {
              x: 20,
              y: 20,
            },
            faceTo: "map",
            rotate: 180,
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/car.png",
          }),
          start: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: start,
          }),
          end: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: end,
          }),
          marker: new TMap.MarkerStyle({
            width: 18,
            height: 25,
          }),
          newmarker: new TMap.MarkerStyle({
            width: 22,
            height: 30,
            src: address,
          }),
        },
        geometries: [
          {
            id: "car",
            styleId: "car-down",
            position: this.traceInfo[0],
          },
          {
            id: "start",
            styleId: "start",
            position: this.traceInfo[0],
          },
          {
            id: "end",
            styleId: "end",
            position: this.traceInfo[this.traceInfo.length - 1],
          },
          ...this.points,
        ],
      });
      //初始化mark标记点点击事件
      this.infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      this.infoWindow.close(); //初始关闭信息窗关闭
      //监听标注点击事件
      this.marker.on("click", function(evt) {
        let params = {
          lat: evt.geometry.position.lat,
          lng: evt.geometry.position.lng,
        };
        // let params = `${evt.geometry.position.lat},${evt.geometry.position.lng}`
        reverseCoord(params).then((res) => {
          //设置infoWindow
          _self.infoWindow.open(); //打开信息窗
          _self.infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
          //设置信息窗内容
          _self.infoWindow.setContent(`<div>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">地址：</span>${res.data.address}</p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${evt.geometry.position.lng}-${evt.geometry.position.lat}</p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">时间：</span>${evt.geometry.properties.time}</p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">停留时间：</span>${evt.geometry.properties.TimeDiffer}小时</p>
            <p>
              <button class="address_btn" onclick="shipment('${res.data.address}','${evt.geometry.properties.time}','${evt.geometry.position.lng}','${evt.geometry.position.lat}')">设置装货地</button>
              <button class="address_btn" onclick="unloading('${res.data.address}','${evt.geometry.properties.time}','${evt.geometry.position.lng}','${evt.geometry.position.lat}')">设置卸货地</button>
            </p>
            </div>`);
        });
      });
      this.circle = new TMap.MultiCircle({
        map: this.map,
        styles: {
          // 设置圆形样式
          circle: new TMap.CircleStyle({
            color: "rgba(41,91,255,0.16)",
            showBorder: true,
            borderColor: "rgba(41,91,255,1)",
            borderWidth: 2,
          }),
        },
        geometries: [],
      });
      // this.startMove()
    },
    // 装货地围栏
    shipment(address, time, lng, lat) {
      if (
        this.fenceList.length === 0 ||
        this.fenceList[this.fenceList.length - 1].type === "end"
      ) {
        this.infoWindow.close();
        this.$prompt("请输入半径(米)", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: this.radiusValue,
          closeOnClickModal: false,
        })
          .then(({ value }) => {
            this.circle.add({
              styleId: "circle",
              center: new TMap.LatLng(lat, lng),
              radius: Number(value),
              id: "circle" + lat + lng,
            });
            this.fenceList.push({
              address,
              time: String(time)
                .split("／")
                .join(" "),
              lng,
              lat,
              type: "start",
              radius: Number(value),
            });
          })
          .catch(() => {});
      } else {
        this.$message.warning("请选择卸货地围栏");
        return;
      }
    },
    // 卸货地围栏
    unloading(address, time, lng, lat) {
      if (
        this.fenceList[this.fenceList.length - 1].lat == lat &&
        this.fenceList[this.fenceList.length - 1].lng == lng
      ) {
        this.$message.warning("卸货地围栏和装货地围栏不能是同一个位置");
        return;
      }
      if (
        this.fenceList.length === 0 ||
        this.fenceList[this.fenceList.length - 1].type === "end"
      ) {
        this.$message.warning("请选择装货地围栏");
        return;
      } else {
        this.infoWindow.close();
        this.$prompt("请输入半径(米)", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: this.radiusValue,
          closeOnClickModal: false,
        })
          .then(({ value }) => {
            this.circle.add({
              styleId: "circle",
              center: new TMap.LatLng(lat, lng),
              radius: Number(value),
              id: "circle" + lat + lng,
            });
            this.fenceList.push({
              address,
              time: String(time)
                .split("／")
                .join(" "),
              lng,
              lat,
              type: "end",
              radius: Number(value),
            });
          })
          .catch(() => {});
      }
    },
    // 装货地卸货地围栏搜索
    async fenceSearch() {
      if (this.fenceList && this.fenceList.length === 0) {
        this.$message.warning("请选择一组装货地围栏和卸货地围栏");
        return;
      }
      if (this.fenceList[this.fenceList.length - 1].type != "end") {
        this.$message.warning("请选择卸货地围栏");
        return;
      }
      this.newArrList = [];
      this.tableData = [];
      for (let index in this.fenceList) {
        if (this.fenceList[index].type == "start") {
          this.newArrList.push({
            start: this.fenceList[index],
            end: this.fenceList[Number(index) + 1],
          });
        }
      }
      this.addressList = [];
      for (let index in this.newArrList) {
        let params = {
          vehicleNumber: this.bindForm.VehicleCarNumber,
          startDate: this.newArrList[index].start.time,
          endDate: this.newArrList[index].end.time,
          stCoord:
            this.newArrList[index].start.lng +
            "|" +
            this.newArrList[index].start.lat,
          edCoord:
            this.newArrList[index].end.lng +
            "|" +
            this.newArrList[index].end.lat,
          stRadius: this.newArrList[index].start.radius,
          edRadius: this.newArrList[index].end.radius,
          redisKey: this.redisKey,
          pointFlag: this.PointFlag,
        };
        this.loadingFence = true;
        await this.GetLoadingPoint(params, index);
      }
    },
    // GetLoadingPointList 获取起始装货点信息列表
    async GetLoadingPoint(params, index) {
      await GetLoadingPointList(params).then((res) => {
        this.addressList = this.addressList.concat(res.data);
        if (index == this.newArrList.length - 1) {
          this.loadingFence = false;
        }
      });
    },
    // 删除围栏
    removeFence(row, index) {
      let newArr = this.fenceList.filter(
        (item) => item.lat != row.lat && item.lng != row.lng
      );
      this.$nextTick(() => {
        this.fenceList = newArr;
        this.circle.remove(this.circle.geometries[index].id);
      });
    },
    //开始移动
    startMove() {
      this.marker.moveAlong(
        {
          car: {
            path: this.traceInfo,
            speed: 5000,
          },
        },
        {
          autoRotation: true,
        }
      );
    },
    //停止移动
    pauseMove() {
      this.marker.pauseMove();
    },
    //重新开始
    resumeMove() {
      this.marker.resumeMove();
    },
    // 删除装货地卸货地
    removeAddress(row) {
      let newArr = this.addressList.filter(
        (item) =>
          item.LoadCoord != row.LoadCoord && item.UnLoadCoord != row.UnLoadCoord
      );
      this.$nextTick(() => {
        this.addressList = newArr;
      });
    },
    // 轨迹匹配
    locusSearch() {
      if (this.addressList.length === 0) {
        this.$message.warning("请选择装货地卸货地");
        return;
      }
      let params = {};
      let num = 1;
      let paramsList = [];
      // 获得里程的参数
      for (let item of this.addressList) {
        // params = {
        //   searchWord: this.bindForm.VehicleCarNumber,
        //   stDate: item.LoadingDate,
        //   edDate: item.UnLoadingDate,
        //   coordType: 2, // 1是百度地图轨迹 2是腾讯地图轨迹
        //   redisKey: this.redisKey
        // }
        paramsList.push({
          vehicleNumber: this.bindForm.VehicleCarNumber,
          vehicleColor: this.vehicleColor,
          startDate: item.LoadingDate,
          endDate: item.UnLoadingDate,
          StCoord: item.LoadCoord,
          EdCoord: item.UnLoadCoord,
          redisKey: item.RedisKey,
          TrackKey: this.redisKey,
        });
        let str = `pl-${num++}`;
        // this.addPolyArr(params, str)
      }
      this.getlocus(paramsList);
    },
    // 添加新的轨迹
    addPolyArr(params, str) {
      this.loading = true;
      getEquipTrack(params)
        .then((res) => {
          if (res.data.list.length > 0) {
            let arr = res.data.list.map((item) => {
              return new TMap.LatLng(item.Lat, item.Lng);
            });
            this.newtraceInfo = arr;
            this.loading = false;
            this.polylineLayer.add({
              //新的折线添加到图层中
              id: str,
              styleId: "style_red",
              paths: this.newtraceInfo,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取分段轨迹列表
    getlocus(params) {
      this.tableData = [];
      this.loadingTable = true;
      GetMileageInfo(params)
        .then((res) => {
          for (let index in this.addressList) {
            this.tableData.push({
              VehicleNumber: res.data[index].VehicleNumber || "",
              Mileage: res.data[index].Mileage,
              loadingPoint: this.addressList[index].LoadAddress,
              unloadingPoint: this.addressList[index].UnLoadAddress,
              startDate: this.addressList[index].LoadingDate,
              endDate: this.addressList[index].UnLoadingDate,
            });
            this.loadingTable = false;
          }
          this.mileageList = JSON.parse(JSON.stringify(this.tableData));
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },
    // 删除分段轨迹
    removelocus(row) {
      let newArr = this.tableData.filter(
        (item) => JSON.stringify(row) != JSON.stringify(item)
      );
      this.$nextTick(() => {
        this.tableData = newArr;
      });
    },
    // 导出分段轨迹
    exportExcel() {
      ExportMileageInfo(this.tableData).then((res) => {
        window.open(res.data);
      });
    },
    changePointFlag(val) {
      // console.log(val);
      // if(val.length > 0){
      //   this.PointFlag = val.reduce((prep,curr) => {
      //     return prep+curr;
      //   });
      // } else {
      //   this.PointFlag = 0
      // }
    },
    handleMileage() {
      if (this.mileage != "" && this.mileageList.length > 0) {
        this.isDanger = false;
        this.tableData = [];
        for (let item of this.mileageList) {
          if (Number(item.Mileage) >= Number(this.mileage)) {
            item.dander = true;
            this.tableData.push({
              ...item,
              dander: true,
            });
          } else {
            this.tableData.push({
              ...item,
              dander: false,
            });
          }
        }
        this.$nextTick(() => {
          this.isDanger = true;
        });
      } else {
        this.isDanger = false;
        this.tableData = [];
        for (let item of this.mileageList) {
          this.tableData.push({
            ...item,
            dander: false,
          });
        }
        this.$nextTick(() => {
          this.isDanger = true;
        });
      }
    },
  },
};
</script>
<style lang="scss">
.deviceSkim {
  .circle {
    display: inline-block;
    width: 55px;
    height: 55px;
    background: green;
    border-radius: 50%;
  }
}
.address_btn {
  background-color: #409eff;
  border-color: #409eff;
  padding: 7px 14px;
  color: #fff;
  border: none;
  border-radius: 4px;
}
.warning-row {
  color: red;
}
</style>
<style lang="scss" scoped>
.deviceSkim {
  display: flex;
  flex-direction: column;
  .strengthen {
    margin-top: 20px;
    .imgs {
      margin-right: 10px;
    }
  }
  ul li {
    list-style: none;
  }
  .btn-wrap {
    z-index: 999;
    position: absolute;
    bottom: 5.5rem;
    margin-left: 3rem;
    padding: 1rem 1rem;
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
  }
  .btn {
    width: 75px;
    height: 30px;
    float: left;
    background-color: #fff;
    color: rgba(27, 142, 236, 1);
    font-size: 14px;
    border: 1px solid rgba(27, 142, 236, 1);
    border-radius: 5px;
    margin: 0 5px;
    text-align: center;
    line-height: 30px;
  }
  .btn:hover {
    background-color: rgba(27, 142, 236, 0.8);
    color: #fff;
  }
  .map {
    width: 100%;
    height: 1000px;
  }
  .circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: green;
    border-radius: 50%;
  }
  .outerBox {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    .top {
      display: flex;
      // justify-content: space-around;
      margin-bottom: 20px;
      .device {
        margin-right: 60px;
      }
    }
  }
  .innerBox {
    display: flex;
    .state {
      width: 20%;
    }
  }
}
.pageTitle {
  display: inline-block;
  margin-right: 16px;
}
.facilityBox {
  .locus_table {
    background-color: #fff;
    overflow: hidden;
    height: 83vh;
    padding: 12px 10px 0px;
    .el-button {
      float: right;
    }
  }
  .content_box {
    display: flex;
    flex: 1;
  }
  .shipment {
    height: 83vh;
    width: 18%;
    min-width: 286px;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    padding-right: 10px;
    .shipment_item {
      border: 1px solid #eee;
      width: 100%;
      height: 41.5vh;
      position: relative;
      .address_box {
        height: 36vh;
        overflow-y: auto;
      }
      .addressFence_search {
        position: absolute;
        bottom: 10px;
        width: 90%;
        left: 50%;
        transform: translateX(-51%);
      }
      .addressFence_radio {
        position: absolute;
        bottom: 50px;
        width: 90%;
        left: 50%;
        transform: translateX(-51%);
      }
      .address_item {
        padding: 10px;
        margin-bottom: 8px;
        box-sizing: border-box;
        width: auto;
        display: flex;
        justify-content: start;
        :first-child {
          min-width: 52px;
        }
        .item_btn {
          padding: 2px;
        }
        .item_address {
          display: inline-block;
        }
      }
    }
  }
  .mapBox {
    width: 82%;
    min-width: 750px;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
  }
  .locus_btnBox {
    margin-top: 6px;
    width: auto;
    // display: flex;
    // flex: 1;
    // justify-content: space-between;
    // line-height: 36px;
    .address_search {
      // float: left;
    }
  }
}
</style>
